export * from './queries'
export * from './responses'

export interface IClient {
  Id: number
  VAT: string
  Name: string
  Type: string
  Address: string
  ZipId: number
  ZipCode: string
  City: string
  DebitorNumber: string
  AGCOProductCount: number
  OtherProductCount: number
  PartsCYPotential: number
  PartsCYProjection: number
  PartsYTDPotential: number
  PartsYTD: number
  PartsCounterYTD: number
  PartsYTDResidual: number
  PartsYTDUtilizationPct: number
  PartsR12Potential: number
  PartsR12: number
  PartsCounterR12: number
  PartsR12Residual: number
  PartsR12UtilizationPct: number
  LabourYTDPotential: number
  LabourYTD: number
  LabourYTDResidual: number
  LabourYTDUtilizationPct: number
  LabourR12Potential: number
  LabourR12: number
  LabourR12Residual: number
  LabourR12UtilizationPct: number
  LastInvoiceDate: string
  LoyaltyGroup: string
  NaceSegmentId: number
  NaceSegmentName: string
  FieldSizeId: number
  FieldSizeName: string
  FieldSize: number
  KeyAccount: boolean
  KeyAccountString: string
  [key: string]: any
}

export interface IListItem {
  text: string
  value: string | number
  disabled: boolean
}

export interface IPartsData {
  CompanyId: number
  CompanyName: string
  ByMonth: {
    Items: any[]
    R12: number
    R12LY: number
  }
  ByDebitor: {
    Items: any[]
    FootCount: number
    FootR12LY: number
    FootR12: number
    FootDelta: number
    FootDeltaPct: number
  }
  BySegment: {
    Items: any[]
    FootCount: number
    FootR12LY: number
    FootR12: number
    FootDelta: number
    FootDeltaPct: number
  }
  ByPMF: {
    Items: any[]
    FootCount: number
    FootR12LY: number
    FootR12: number
    FootDelta: number
    FootDeltaPct: number
  }
}

export interface IPartsMetadata {
  Debitors: IListItem[]
  Segments: IListItem[]
  PMFs: IListItem[]
  PMDs: IListItem[]
  Zips: IListItem[]
  Groupings: IListItem[]
}

export class ContractDetail {
  CompanyClient_Id = 0
  CompanyClient_Name = ''
  CompanyClient_VAT = ''
  CompanyClient_DebitorNumber = ''
  CompanyClient_LoyaltyGroup = ''
  CompanyClient_KeyAccount = false
  CompanyClient_PartsR12Potential = 0
  CompanyClient_PartsAndCounterR12 = 0
  CompanyClient_PartsAndCounterYTD = 0
  ResponsibleSalesman_Id: number | null = null
  Period: string[] | null = null
  FirstContract = false
  ClientAcceptedOn = ''
  Note = ''
  ActualYTD = 0
  ActualR12 = 0
  Potential = 0
  AgreedTurnoverParts? = 0
  ActualLY = 0
  ActualLYToDate = 0
  Actual = 0
  Projection = 0
  Expectation = 0
  IncrementalSale = 0
  ExpectationRatio = 0
  DeltaSale = 0
  IncrementalSaleToDate = 0
  ExpectationVsAgreed = 0
  ExpectationVsLastYear = 0
  TradeAgreement = false
  Periods: any[] = []
}

export class Profile {
  Id = 0
  Name = ''
  Email = ''
  IsAdmin = false
  DefaultZipIds: number[] = []
  Company = new Company()
  LastLogin?: string
  ExpireOn?: string
}

export class Company {
  Id = 0
  Code = ''
  Name = ''
  CountryCode = ''
  DealerCode = ''
  DataUpdatePending = false
  SalesDataImporterLastJob?: string
  MaxInvoiceDate?: string
  ExpireOn?: string
}

// Workaround for Quasar table columns
export type AlignType = 'left' | 'right' | 'center' | undefined