<script setup lang="ts">
import { onMounted } from 'vue';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n'
import { api } from '../plugins/axios'
import { useAuthStore } from '@/stores/auth';

type Result = {
  Errors: any[]
  Model: string
}

const authStore = useAuthStore()
const { t } = useI18n()
const updating = ref(false)
const result = ref<Result | undefined>()
const show = ref(false)

async function update () {
  updating.value = true

  try {
    const { data } = await api.post('company/executedataupdate')
    result.value = data

    authStore.loginWithAccessToken()
  } catch (error) {
    console.error(error)
  } finally {
    updating.value = false
  }
}
</script>

<template>
  <v-btn size="small" variant="tonal" color="primary" @click="update">
    Opdater

    <v-dialog v-model="show" activator="parent" width="800" persistent>
      <v-card title="Opdater data">
        <v-card-text>
          <v-fade-transition group mode="out-in">
            <div v-if="updating" key="loading" class="d-flex flex-column align-start justify-center" style="gap: .5rem;">
              <v-progress-linear indeterminate></v-progress-linear>
              <div>Vent venligst</div>
            </div>

            <div v-if="result && !result.Errors.length" key="success">
              <v-alert type="success">
                Data blev opdateret. Du kan nu lukke vinduet.
              </v-alert>
            </div>

            <div v-else-if="result && result.Errors.length" key="error">
              Der skete flg. fejl ved opdatering af data:

              <ul>
                <li v-for="item in result.Errors">
                  {{ item }}
                </li>
              </ul>
            </div>
          </v-fade-transition>
        </v-card-text>

        <v-card-actions>
          <v-btn @click="show = false">Luk</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-btn>
</template>