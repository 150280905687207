import axios from 'axios'

interface IUser {
  AccessToken: string;
  ExpiresOn: string;
  Profile: object;
}

export const api = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL ?? 'http://localhost/AGCO.DP.Web'
})