import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createI18n, useI18n } from 'vue-i18n'
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n'
import en from './locales/en'
import da from './locales/da'
import App from './App.vue'
import router from './router'
import './plugins/chart'
import './plugins/helpers.js'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import * as Sentry from '@sentry/vue'

// Vue i18n
const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: 'da',
  fallbackLocale: 'en',
  messages: { en, da }
})

// Vuetify
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.min.css'
import { md2, md3 } from 'vuetify/blueprints'
import { createVuetify, type ThemeDefinition } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const agcoCustomTheme: ThemeDefinition = {
  dark: false,
  colors: {
    background: '#f9f9f9',
    surface: '#fff',
    primary: '#c41230',
    'on-primary': '#fff'
  }
}

// Quasar
import { Quasar } from 'quasar'
import quasarIconSet from 'quasar/icon-set/mdi-v7'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/mdi-v7/mdi-v7.css'
import 'quasar/dist/quasar.css'

const vuetify = createVuetify({
  components,
  directives,
  blueprint: md2,
  locale: {
    adapter: createVueI18nAdapter({ i18n, useI18n })
  },
  theme: {
    defaultTheme: 'agcoCustomTheme',
    themes: {
      agcoCustomTheme
    }
  }
})

const app = createApp(App)

// Sentry
Sentry.init({
  app,
  dsn: 'https://ee2d79506dcb8084f15a94674271d8cb@o876503.ingest.sentry.io/4505996839485440',
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router)
    }),
    new Sentry.Replay()
  ],
  // Performance
  trackComponents: true,
  tracesSampleRate: 1.0, // Should be reduced in production
  // Session replay
  replaysSessionSampleRate: 0.1, // Should be reduced in production
  replaysOnErrorSampleRate: 1.0
})

app.config.globalProperties.$filters = {
  currencyDKK(value: number) {
    return new Intl.NumberFormat('da-dk').format(value)
  }
}

app.component('VueDatePicker', VueDatePicker)

app.use(createPinia())
app.use(router)
app.use(vuetify)
app.use(i18n)
app.use(Quasar, {
  iconSet: quasarIconSet
})

app.mount('#app')
