<script setup lang="ts">
import { RouterLink, RouterView } from 'vue-router'
import { computed, ref, onBeforeMount } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import logo from '@/assets/AGCO.png'
import { useAuthStore } from './stores/auth'
import UpdateDataView from './views/UpdateDataView.vue'

const { t } = useI18n({ useScope: 'global' })
const authStore = useAuthStore()

const hideNavRouteNames = ["login"]
const profileMenu = ref(false)
const showUpdateDataDialog = ref(false)

const showNav = computed(() => {
  const routeName = useRoute().name?.toString()

  return routeName && !hideNavRouteNames.includes(routeName) ? true : false
})

onBeforeMount(() => {
  authStore.getUserFromLocalStorage()
})
</script>

<template>
  <v-app>  
    <v-app-bar v-if="showNav" density="comfortable">
      <v-app-bar-title>
        <div class="d-flex align-center">
          <img :src="logo" alt="AGCO logo" width="120">
        </div>
      </v-app-bar-title>
      <template #append>
        <div class="d-flex align-center" style="gap: .2rem">
          <v-btn prepend-icon="mdi-view-dashboard-outline" :text="t('dashboard')" to="/"></v-btn>
          <v-btn prepend-icon="mdi-account-group-outline" :text="t('customer', 2)" to="/clients"></v-btn>
          <v-btn prepend-icon="mdi-tractor-variant" :text="t('machine_parc')" to="/products"></v-btn>
          <v-btn prepend-icon="mdi-bullseye-arrow" :text="(t('goal'))" to="/contracts"></v-btn>
          <v-btn prepend-icon="mdi-tools" :text="t('part', 2)" to="/parts"></v-btn>

          <v-btn append-icon="mdi-account-circle-outline">
            <template #prepend v-if="authStore.profile.Company?.DataUpdatePending">
              <v-icon icon="mdi-alert-circle" color="warning"></v-icon>
            </template>
            <span v-if="authStore.profile">
              {{ authStore.profile.Name }} <span v-if="authStore.profile.IsAdmin">({{ authStore.profile.Company.Name }})</span>
            </span>
            <v-menu activator="parent">
              <v-list>
                <v-list-item v-if="authStore.profile.Company?.DataUpdatePending">
                  <v-alert density="compact" color="warning" variant="tonal">
                    Ny data tilgængelig

                    <template #append>
                      <UpdateDataView :show="showUpdateDataDialog" />
                    </template>
                  </v-alert>
                </v-list-item>
                <v-list-item :title="t('about')" append-icon="mdi-information-outline" to="/about"></v-list-item>
                <v-list-item :title="t('profile')" append-icon="mdi-badge-account-outline" to="/profile" />
                <v-list-item :title="t('gdpr_info')" append-icon="mdi-check-decagram-outline" to="/gdpr" />
                <v-divider></v-divider>
                <v-list-item :title="t('import_files')" append-icon="mdi-file-import-outline" to="/company/upload"></v-list-item>
                <v-list-item :title="t('debitor_mapping')" append-icon="mdi-arrow-decision" to="/company/debitor-mapping" />
                <v-list-item :title="t('debitors_no_vat')" append-icon="mdi-script-text-outline" to="/company/debitors-without-vat" />
                <v-divider></v-divider>
                <template v-if="authStore.profile.IsAdmin">
                  <v-list-item :title="t('simulate_company')" append-icon="mdi-home-switch-outline" to="/company/simulate"></v-list-item>
                  <v-divider></v-divider>
                  <v-list-item :title="t('import_customer')" to="/import/customer-details"></v-list-item>
                  <v-list-item :title="t('import_parc')" to="/import/products"></v-list-item>
                  <v-list-item :title="t('import_warranty')" to="/import/product-registrations"></v-list-item>
                  <v-list-item :title="`${t('import')} ${t('part', 2)}`" to="/import/parts"></v-list-item>
                  <v-divider></v-divider>
                  <v-list-item :title="t('logs')" append-icon="mdi-script-text-outline" to="/logs"></v-list-item>
                </template>
                <v-list-item :title="t('sign_out')" append-icon="mdi-logout" @click="authStore.signout" />
              </v-list>
            </v-menu>
          </v-btn>
        </div>
      </template>
    </v-app-bar>

    <v-main>
      <RouterView />
    </v-main>

    <v-footer app border color="primary">
      <div class="d-flex justify-space-between w-100" style="gap: 1rem">
        <div>
          © {{ new Date().getFullYear() }} - AGCO
        </div>
        <v-spacer></v-spacer>
        <a href="https://www.proff.dk/" target="_blank" style="color: #fff">PROFF</a>
        <a href="https://www.arla.dk/om-arla/ejere/arlapris/2019/" target="_blank" style="color: #fff">ARLA PRIS</a>
        <a href="https://www.danishcrown.com/da-dk/ejer/griseleverandoer/aktuel-grisenotering/" target="_blank" style="color: #fff">DANISH CROWN/GRISE</a>
        <a href="https://www.agcopartsandservice.com/eame/da_DK.html" target="_blank" style="color: #fff">AGCO PARTS & SERVICE</a>
        <a href="https://aaa.agcocorp.com/auth/XUI/?realm=/dealers&forward=true&spEntityID=emeliferayprodsp&goto=/SSOPOST/metaAlias/dealers/idp?ReqID%3D_d648fbd9570ba2d3653b38a865c4e3a2dcec94b4%26index%3Dnull%26acsURL%3Dhttps://eme.oneagco.com/c/portal/saml/acs%26spEntityID%3Demeliferayprodsp%26binding%3Durn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST&AMAuthCookie=#login/" target="_blank" style="color: #fff">AGCONET</a>
        <a href="https://agcocorp.sharepoint.com/:f:/r/sites/AGCODanmarkAGCOEMEIT/Ekstern/Forhandlere/Eftermarked%20%26%20Marketing?csf=1&web=1&e=233G1d" target="_blank" style="color: #fff">MARKETINGBOX</a>
      </div>
    </v-footer>
  </v-app>
</template>