import { useAuthStore } from '@/stores/auth'
import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: () => import('../views/DashboardView.vue')
    },
    {
      path: '/login',
      name: 'login-layout',
      component: () => import('../layouts/AuthLayout.vue'),
      children: [
        {
          path: '',
          name: 'login',
          component: () => import('../views/LoginView.vue'),
          meta: { anonymous: true }
        },
        {
          path: 'forgot',
          name: 'forgot-password',
          component: () => import('../views/ForgotPasswordView.vue'),
          meta: { anonymous: true }
        }
      ]
    },
    {
      path: '/clients',
      children: [
        {
          path: '',
          name: 'clients',
          component: () => import('../views/ClientsView.vue')
        },
        {
          path: ':id',
          name: 'client',
          component: () => import('../views/ClientDetailView.vue')
        },
      ]
    },
    {
      path: '/products',
      name: 'products',
      component: () => import('../views/ProductsView.vue')
    },
    {
      path: '/parts',
      name: 'parts',
      component: () => import('../views/PartsView.vue')
    },
    {
      path: '/contracts',
      children: [
        {
          path: '',
          name: 'contracts',
          component: () => import('../views/ContractsView.vue')
        },
        {
          path: ':id',
          name: 'contract',
          component: () => import('../views/ContractDetailView.vue')
        },
        {
          path: 'create',
          name: 'create-contract',
          component: () => import('../views/ContractDetailView.vue')
        }
      ]
    },
    {
      path: '/company',
      children: [
        {
          path: 'upload',
          name: 'company-upload',
          component: () => import('../views/CompanyUploadView.vue')
        },
        {
          path: 'debitor-mapping',
          name: 'debitor-mapping',
          component: () => import('../views/DebitorMappingView.vue')
        },
        {
          path: 'debitors-without-vat',
          name: 'debitors-without-vat',
          component: () => import('../views/DebitorsWithoutVATView.vue')
        },
        {
          path: 'simulate',
          name: 'simulate-company',
          component: () => import('../views/SimulateCompanyView.vue'),
          meta: { requiresAdmin: true }
        },
      ]
    },
    {
      path: '/import',
      children: [
        {
          path: 'customer-details',
          name: 'import-customer-details',
          component: () => import('../views/ImportCustomerDetailsView.vue'),
          meta: { requiresAdmin: true }
        },
        {
          path: 'products',
          name: 'import-products',
          component: () => import('../views/ImportProductsView.vue'),
          meta: { requiresAdmin: true }
        },
        {
          path: 'product-registrations',
          name: 'import-product-registrations',
          component: () => import('../views/ImportProductRegistrationsView.vue'),
          meta: { requiresAdmin: true }
        },
        {
          path: 'parts',
          name: 'import-parts',
          component: () => import('../views/ImportPartsView.vue'),
          meta: { requiresAdmin: true }
        },
      ]
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('../views/AboutView.vue')
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('../views/ProfileView.vue')
    },
    {
      path: '/logs',
      name: 'logs',
      component: () => import('../views/LogsView.vue'),
      meta: { requiresAdmin: true }
    },
    {
      path: '/gdpr',
      name: 'gdpr',
      component: () => import('../views/GDPRInfoView.vue')
    },
    {
      path: '/:pathMatch(.*)',
      name: 'not-found',
      component: () => import('../views/NotFoundView.vue'),
      meta: { anonymous: true }
    }
  ]
})

/** Global navigation guards */

// When user is not found, redirect to login
router.beforeEach((to, from) => {
  const user = localStorage.getItem('clp_user')

  if (!to.meta.anonymous && !user && to.name !== 'login') {
    return { name: 'login' }
  }
})

// Routes that require admin priviledges can only be accessed by admins
router.beforeResolve(async (to) => {
  if (to.meta.requiresAdmin) {
    const authStore = useAuthStore()

    if (!authStore.profile.IsAdmin) {
      return { name: 'dashboard' }
    }
  }
})

export default router
