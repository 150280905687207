import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { Profile, type IAuthResponse } from '@/interfaces'
import { api } from '@/plugins/axios'
import * as Sentry from '@sentry/vue'

export const useAuthStore = defineStore('auth', () => {
  const profile = ref<Profile>(new Profile())
  const accessToken = ref()
  const expiresOn = ref()

  const maxInvoiceDate = computed(() => profile.value?.Company.MaxInvoiceDate)

  function getUserFromLocalStorage() {
    const clpUser: IAuthResponse = JSON.parse(localStorage.getItem('clp_user') as string)

    if (clpUser) {
      profile.value = clpUser.Profile
      accessToken.value = clpUser.AccessToken
      expiresOn.value = clpUser.ExpiresOn

      // Check that access token is not expired
      if (clpUser.ExpiresOn && new Date(clpUser.ExpiresOn) > new Date()) {
        api.defaults.headers['X-UAT'] = clpUser.AccessToken

        // Set Sentry user
        Sentry.setUser({
          id: clpUser.Profile.Id,
          username: clpUser.Profile.Name,
          email: clpUser.Profile.Email
        })
      } else {
        console.log('Access token is expired. User has to reauthenticate.')

        localStorage.removeItem('clp_user')
        window.location.href = '/login'
      }
    }
  }

  async function loginWithAccessToken() {
    try {
      const response = await api.post('user/authWithToken?accessToken=' + accessToken.value)

      const currentUser: IAuthResponse = JSON.parse(localStorage.getItem('clp_user') as string)

      currentUser.Profile = response.data

      localStorage.setItem('clp_user', JSON.stringify(currentUser))
      getUserFromLocalStorage()
    } catch (error) {
      console.error(error)
    }
  }

  function syncProfileToLocalStorage() {
    const current: IAuthResponse = JSON.parse(localStorage.getItem('clp_user') as string)

    current.Profile = profile.value

    localStorage.setItem('clp_user', JSON.stringify(current))
  }

  async function signout() {
    localStorage.removeItem('clp_user')
    profile.value = new Profile()
    delete api.defaults.headers['X-UAT']
    Sentry.setUser(null)
    window.location.href = '/login'
  }

  return { profile, maxInvoiceDate, getUserFromLocalStorage, loginWithAccessToken, syncProfileToLocalStorage, signout }
})
